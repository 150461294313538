import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'
import { t } from 'i18next'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

import { useDashboardStore } from '../../../entities/dashboard'
import { AlertMessage } from '../../../shared/ui/alert-message'

import { ChartAlertMessage } from './alerts'

export const AnnualProgressRadar = () => {
  const { annualProgress, annualIsLoading, annualHasError } = useDashboardStore()
  const annualContainerRef = useRef<HTMLDivElement | null>(null)
  const annualChartRef = useRef<ReactApexChart | null>(null)

  const { sortedAnnualProgress, series, options } = useMemo(() => {
    if (annualIsLoading || annualHasError || !annualProgress) {
      return { sortedAnnualProgress: [], series: [], options: {} }
    }

    // Сортируем данные по месяцу
    const sortedAnnualProgress = annualProgress.sort(
      (a, b) => new Date(a.month).getTime() - new Date(b.month).getTime())

    const saleRevenueData = sortedAnnualProgress.map(item => item.saleRevenue)
    const costData = sortedAnnualProgress.map(item => item.cost)
    const profitConfirmedData = sortedAnnualProgress.map(item => item.profitConfirmed)

    // Преобразуем формат YYYY/MM в название месяца
    const labelData = sortedAnnualProgress.map(item => {
      const date = new Date(`${item.month}/01`)
      return date.toLocaleString('default', { month: 'long' })
    })

    const series = [
      {
        name: 'Revenue',
        type: 'column',
        data: saleRevenueData,
      },
      {
        name: 'Profit',
        type: 'area',
        data: profitConfirmedData,
      },
      {
        name: 'Cost',
        type: 'line',
        data: costData,
      },
    ]

    const options: ApexOptions = {
      chart: {
        height: 350,
        type: 'line',
        redrawOnParentResize: true,
        redrawOnWindowResize: true,
        stacked: false,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false, // Отключаем зум
        },
        selection: {
          enabled: false, // Отключаем выделения
        },
      },
      stroke: {
        width: [0, 2, 5],
        curve: 'smooth',
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
        },
      },
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: labelData,
      markers: {
        size: 0,
      },
      xaxis: {
        type: 'category',
        labels: {
          formatter: function (value) {
            return value
          },
        },
      },
      yaxis: {
        title: {
          text: 'Points',
        },
        min: 0,
        labels: {
          formatter: function (value) {
            return value.toFixed(0)
          },
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return y.toFixed(2) + ' $ '
            }
            return y
          },
        },
      },
    }

    return { sortedAnnualProgress, series, options }
  }, [annualProgress, annualIsLoading, annualHasError])

  if (annualIsLoading) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="info">
          {t('kacc:Just a minute! Chart is loading/updating!')}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  if (annualHasError) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="error">
          {t(annualHasError)}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  if (!sortedAnnualProgress || sortedAnnualProgress.length === 0) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="warning">
          {t('kacc:Oops! The Chart is - no data!')}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  return (
    <ChartContainer ref={annualContainerRef}>
      <ReactApexChart
        ref={annualChartRef}
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </ChartContainer>
  )
}

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`